import React from 'react';
import './features.css'
import {Feature} from '../../components';

const featuresData = [
    {
        title: 'Адекватные цены',
        text: 'Не дорогая цена вызова мастера, прайс на работы зависит от их объёма и сложности работ'
    },
    {
        title: 'Конфидециальность',
        text: 'Информация о случившемся гарантированно не станет достоянием третьих лиц'
    },
    {
        title: 'Репутация',
        text: '99% клиентов довольны нашей работой и рекомендуют компанию близким и знакомым'
    },
]

const Features = () => {
    return(
        <div className='fastKey__features section__padding' id='features'>
            <div className="fastKey__features-heading">
                <h1 className="gradient__text">Обращаясь к нам вы получаете ряд неоспоримых преимуществ:</h1>
                <p>Экстренное открывание квартир, домов, офисов; Аварийное открывание автомобилей всех марок и гаражных замков; Открывание сейфов, межкомнатных дверей, мебели и ролетов; Установка, ремонт и замена замков; Изготовление ключей любой сложности; Установка и изготовление бронированных дверей; Разблокировка сигнализации.</p>
            </div>
            <div className="fastKey__futures-container">
                {featuresData.map((item, index) => (
                    <Feature title={item.title} text={item.text} key={item.title + index}/>
                ))}
            </div>
        </div>
    )
};

export default Features;
