import React from 'react';
import './mainButton.css'
import 'animate.css';
import callImage from '../../assets/call-50.png'


const MainButton = () => {
    return(
        <div className='fastKey__main-button'>
            <a onClick="gtag_report_conversion(); gtag('event', 'клик +380967770070', {'event_category': 'номер телефона_3'});" href='tel:+380967770070'>
                <button>
                    <img className='animate__animated animate__infinite animate__swing animate__delay-2s' src={callImage} alt="fastKey" width='40' height='40'/>
                    Позвонить сейчас
                </button>
            </a>
        </div>
    )
};

export default MainButton;
