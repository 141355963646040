import React from 'react';
import './blog.css'
import {Article} from '../../components';
import {lockWeb, lock, carWeb, car, safeWeb, safe, lockRepairWeb, lockRepair} from './imports'

const Blog = () => {
    return (
        <div className='fastKey__blog section__padding' id='service'>
            <div className="fastKey__blog-heading">
                <h1 className='gradient__text'>Наши услуги</h1>
            </div>
            <div className="fastKey__blog-container">
                <div className="fastKey__blog-container_groupB">
                    <Article imgUrlWeb={lockWeb} imgUrl={lock} title='Вскрытие дверных замков' text='В случае потери ключа или повреждения замков входные двери становятся преградой не только для потенциального грабителя, но и для хозяина квартиры. Не стоит паниковать. У нас найдётся профессиональный мастер, который с вашего официального разрешения осуществит аварийное вскрытие любых видов замков. -вскрытие дверных замков - вскрытие в присутствии владельца - без повреждения двери - в любое время суток - открываем замки любой сложности '/>
                    <Article imgUrlWeb={carWeb} imgUrl={car} title='Вскрытие автомобильных замков' text='Самостоятельное вскрытие замков чревато в первую очередь повреждением лакокрасочного покрытия. Некоторые автовладельцы с целью «экономии» средств предпочитают разбить окно машины, даже не предполагая, что культурное аварийное открытие автомобилей в общем итоге им обойдётся значительно дешевле и быстрее. - вскрываем двери, багажники, бардачки - разблакировываем систему MULTILOCK - работаем с разряженным аккумулятором - в машинах любых возрастов и марок '/>
                    <Article imgUrlWeb={safeWeb} imgUrl={safe} title='Вскрытие замков сейфов' text='Всем известно, что любые ценности лучше всего хранить в сейфе – а что делать, если замок хранилища заклинило или не удаётся вспомнить секретный код? Конечно, вызвать мастера, который осуществит вскрытие замков сейфов, поможет извлечь все предметы и восстановит работоспособность запорного механизма. - открываем механические - чиним электронные - осуществляем перекодировку - не нарушаем целостность '/>
                    <Article imgUrlWeb={lockRepairWeb} imgUrl={lockRepair} title='Ремонт и замена замков' text='Мы не советуем менять замок самостоятельно – можно сломать и сам механизм, и даже дверь. Профессиональная замена замков стоит недорого, но Вы гарантированно будете иметь прекрасно работающее устройство, которое еще долго Вас не подведет. - исправляем любые повреждения - заменяем старые - врезаем новые - устанавливаем дополнительные замки '/>
                </div>
            </div>
        </div>
    )
};

export default Blog;
