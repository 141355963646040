import React, {useState} from 'react';
import './navbar.css'
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from '../../assets/logo-2.svg'

const Menu = () => (
    <>
        <p><a href="#home">Главная</a></p>
        <p><a href="#fastKey">О компании</a></p>
        <p><a href="#service">Услуги</a></p>
        <p><a href="#price">Цены</a></p>
    </>
)


const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false)
    return (
        <div className='fastKey__navbar'>
            <div className="fastKey__navbar-links">
                <div className="fastKey__navbar-links_logo">
                    <a href="https://fastkey.od.ua">
                        <img src={logo} alt="logo"/>
                    </a>
                </div>
                <div className="fastKey__navbar-links_container">
                    <Menu/>
                </div>
            </div>
            <div className="fastKey__navbar-sign">
                <a onClick="gtag_report_conversion(); gtag('event', 'клик 0937770070', {'event_category': 'номер телефона'});"  href="tel:0937770070">
                    <button type='button'>Вызвать мастера</button>
                </a>
            </div>
            <div className="fastKey__navbar-menu">
                {toggleMenu
                    ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)}/>
                    : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)}/>
                }
                {
                    toggleMenu && (
                        <div className="fastKey__navbar-menu_container scale-up-center">
                            <div className="fastKey__navbar-menu_container-links">
                                <Menu/>
                                <div className="fastKey__navbar-menu_container-links-sign">
                                    <a onClick="gtag_report_conversion(); gtag('event', 'клик 0937770070', {'event_category': 'номер телефона'});"  href="tel:0937770070">
                                        <button type='button'>Вызвать мастера</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default Navbar;
