import React from 'react';
import './header.css'
import Image from '../../components/image/Image'
import aiWeb from '../../assets/ain2.webp'
import ai from '../../assets/ain2.png'

const Header = () => {
    return (
        <div className='fastKey__header section__padding' id='home'>
            <div className="fastKey__header-content">
                <h1 style={{color: '#fff'}}>Аварийное вскрытие замков  квартир, машин, сейфов в г.Одессе</h1>
                <p>КРУГЛОСУТОЧНО. Быстро и без повреждения </p>
                <div className="fastKey__header-content__input">
                    <a onClick="gtag_report_conversion(); gtag('event', 'клик 0967770070', {'event_category': 'номер телефона'});"  href="tel:0967770070">
                        <button type='button'>(096) 777-00-70</button>
                    </a>
                    <a onClick="gtag_report_conversion(); gtag('event', 'клик 0937770070', {'event_category': 'номер телефона_2'});" href="tel:0937770070">
                        <button type='button'>(093) 777-00-70</button>
                    </a>
                    <a onClick="gtag_report_conversion(); gtag('event', 'клик 0487890070', {'event_category': 'номер телефона'});" href="tel:0487890070">
                        <button type='button'>(048) 789-00-70</button>
                    </a>
                </div>

                <div className="fastKey__header-content__people">
                    <p>Вскрытие замков любой сложности 24/7 Одесса и область</p>
                </div>
                <div className="fastKey__header-content__bottom">
                    <p>Быстро, аккуратно и профессионально вскроем <span style={{color: 'white', background: 'red'}}>любой</span> замок. Звоните нам <span style={{color: 'white', background: 'blue'}}>круглосуточно</span>, и мы приедем в любую точку города <span style={{color: 'white', background: 'orange'}}>Одессы</span>, Южный, Черноморск(Ильичевск), Затока и поможем Вам.</p>
                </div>
            </div>
            <div className="fastKey__header-image">
                <Image src={aiWeb} fallback={ai} alt='FastKey'/>
            </div>
        </div>
    )
};

export default Header;
