import './App.css';
import { Footer, Blog, Possibility, Features, WhatFastKey, Header} from './containers';
import { FastKey, Navbar} from './components';
import MainButton from './containers/mainButton/MainButton';


function App() {
  return (
    <div className='App'>
      <div className="gradient__bg">
          <Navbar/>
          <Header/>
      </div>
        <WhatFastKey/>
        <Blog/>
        <Features/>
        <Possibility/>
        <FastKey/>
        <Footer/>
        <MainButton/>
    </div>
  );
}

export default App;
