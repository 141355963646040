import React from 'react';
import './possibility.css'

const Possibility = () => {
    return(
        <div className='fastKey__possibility section__padding' id='possibility'>
            <div className="fastKey__possibility-content">
                <h1 className='gradient__text'>Наши плюсы:</h1>
                <p>Большой опыт работы; Выполняем заказы разного уровня сложности; Оперативность; Штат только высококлассных специалистов; Работаем 24 ЧАСА В СУТКИ! Прибываем по адресу в течение 20 минут. Скорее всего, Вы понимаете, что укрепление замков и дверей повышает безопасность и защиту Вашего дома. Крепкие и надёжные замки станут преградой для злоумышленников и незаконного проникновения. В результате, Вы можете спокойно покидать помещение, не опасаясь вторжения.</p>
            </div>
            <div className="fastKey__possibility-content">
                <h1 className='gradient__text'>Дополнительные услуги:</h1>
                <p>Замена замков, в случае если замок не подлежит восстановлению; Реставрация автомобильных замков после взлома; Изготовление ключей по замку. Поломка замка и утеря ключа случается в самый неподходящий момент. Попытка самостоятельно исправить ситуацию приведёт к нежелательным результатам. Выбирайте лучший вариант! Свяжитесь с опытными специалистами, которые решат все «замочные» проблемы в два счёта!</p>
            </div>
        </div>
    )
};

export default Possibility;
