import React from 'react';
import './article.css'
import Image from '../../components/image/Image'

const Article = ({imgUrlWeb, imgUrl, title, text}) => {
    return(
        <div className='fastKey__blog-container_article'>
            <div className="fastKey__blog-container_article-image">
                <Image src={imgUrlWeb} fallback={imgUrl} alt='FastKey image'/>
            </div>
            <div className="fastKey__blog-container_article-content">
                <div>
                    <h3>{title}</h3>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
};

export default Article;
