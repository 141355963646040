import React from 'react';
import './footer.css'


const Footer = () => {
    return(
        <div className='fastKey__footer section__padding'>
            <div className="fastKey__footer-heading">
                <h1 className='gradient__text'>
                        Если под рукой не оказалось ключа, а проблемный замок стал преградой на вашем пути. Мы 24/7 откроем для вас любые замки!
                </h1>
            </div>
            <div className='fastKey__footer-btn' style={{background: 'blue'}}>
                <a onClick="gtag_report_conversion(); gtag('event', 'клик 0967770070', {'event_category': 'номер телефона_3'});" href="tel:0967770070">
                    <p>(096) 777-00-70</p>
                </a>
            </div>
            <div className='fastKey__footer-btn' style={{background: 'yellow'}}>
                <a onClick="gtag_report_conversion(); gtag('event', 'клик 0937770070', {'event_category': 'номер телефона_2'});" href="tel:0937770070">
                <p style={{color: 'blue'}}>(093) 777-00-70</p>
                </a>
            </div>
            <div className='fastKey__footer-btn'>
                <a onClick="gtag_report_conversion(); gtag('event', 'клик 0487890070', {'event_category': 'номер телефона'});" href="tel:0487890070">
                    <p>(048) 789-00-70</p>
                </a>
            </div>
            <div className="fastKey__footer-copyright">
                <p>&copy; {new Date().getFullYear()} <a href='https://www.fastkey.od.ua'>fastkey.od.ua</a>, made by <a href='https://www.vitaliikasap.com'>Vitalii Kasap</a></p>
            </div>
        </div>
    )
};

export default Footer;
