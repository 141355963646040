import React from 'react';
import './whatFastKey.css'
import {Feature} from '../../components';

const WhatFastKey = () => {
    return(
        <div className='fastKey__whatFastKey section__margin' id='fastKey'>
            <div className="fastKey__whatFastKey-heading">
                <h1 className="gradient__text">
                    FastKey - это надёжная защита Ваших замков!
                </h1>
            </div>
            <div className="fastKey__whatFastKey-container">
                <Feature title='Почему стоит обратиться к нам?' text='Практический опыт работы в сфере замков более 15 лет. Высокий профессионализм. Наличие специального инструмента и использование соврменных технологий. Сотрудничаем с частными и юридическими лицами.'/>
                <Feature title='FASTKEY' text='Мы поможем Вам быстро и качественно найти выход из сложившейся ситуации! Вот уже который год к нам обращаются за помощью сотни клиентов, потому как они уверены в получении необходимого результата работы!'/>
                <Feature title='Почему мы?' text='Низкие цены. Без повреждений дверей. Действуем оперативно. Современные технологии.'/>
            </div>
        </div>
    )
};

export default WhatFastKey;
